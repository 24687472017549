import {
    ClickResolveDetails,
    LineItem,
    StripeElementsOptions,
    StripeExpressCheckoutElementOptions
} from '@stripe/stripe-js';

import { ItemType } from '../../types/item';
import { ExpressCheckoutProps } from './types';

const calcPrice = (price: string | number) => Math.floor(parseFloat(price as string) * 100);

export const getElementsOptions = ({
    item: { price, currency }
}: ExpressCheckoutProps): StripeElementsOptions => ({
    mode: 'payment',
    amount: calcPrice(price),
    currency: currency || 'usd'
});

export const getExpressCheckoutOptions = (): StripeExpressCheckoutElementOptions => ({
    buttonHeight: 50,
    layout: {
        maxColumns: 1,
        maxRows: 2
    },
    paymentMethods: {
        link: 'never'
    }
});

export const getLineItems = (item: ItemType, taxAmount = 0): LineItem[] => {
    return [
        { name: item.name, amount: calcPrice(item.price) },
        { name: 'Tax', amount: calcPrice(taxAmount) }
    ];
};

export const getClickResolveDetails = (item: ItemType): ClickResolveDetails => ({
    lineItems: getLineItems(item),
    shippingAddressRequired: true,
    allowedShippingCountries: [
        'US',
        'BE',
        'BR',
        'CA',
        'CY',
        'DK',
        'DO',
        'EE',
        'FR',
        'GE',
        'DE',
        'GR',
        'HK',
        'IN',
        'IE',
        'IT',
        'JP',
        'KR',
        'KW',
        'LI',
        'LU',
        'MX',
        'MC',
        'MA',
        'NL',
        'NZ',
        'NO',
        'PT',
        'PR',
        'QA',
        'RU',
        'SA',
        'SG',
        'ZA',
        'ES',
        'SE',
        'CH',
        'TW',
        'TH',
        'TR',
        'AE',
        'GB',
        'VG'
    ],
    shippingRates: [
        {
            id: 'free-shipping',
            displayName: 'Free shipping',
            amount: 0
        }
    ]
});

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { MatchItem } from 'components';
import Content from 'content';
import { Tracking } from 'services';
import {
    isMatchedStylist,
    MP_EVENTS,
    MP_PROPS,
    MP_VALUES,
    trackEvent
} from 'services/mixpanel';
import { 
    useBestMatchesQuery,
    useFavoriteStylistMutation, 
    useGetFavoriteStylistsQuery, 
    useUnfavoriteStylistMutation
} from 'store/user-service/user-api-slice';

const Matches = ({
    user,
    stylist,
}) => {
    const navigate = useNavigate();
    const { data: stylistsMatchs = [] } = useBestMatchesQuery();
    const { data: favoriteStylists = [] } = useGetFavoriteStylistsQuery();
    const [setFavoriteStylist] = useFavoriteStylistMutation();
    const [setUnfavoriteStylist] = useUnfavoriteStylistMutation();

    const selectStylist = ({ uuid, name }) => {
        Tracking.tag({
            event: 'eec.impressionClick',
            ecommerce: {
                click: {
                    actionField: { list: 'Other Recommended Stylists' },
                    products: [
                        {
                            id: uuid,
                            name,
                            category: '/category/stylist/'
                        }
                    ]
                }
            }
        });
        trackEvent({
            name: MP_EVENTS.MEET_STYLIST,
            properties: {
                [MP_PROPS.STYLIST_NAME]: name,
                [MP_PROPS.STYLIST_UUID]: uuid,
                [MP_PROPS.IS_STYLIST_MATCH]: isMatchedStylist(stylistsMatchs, uuid),
                [MP_PROPS.BOOKING_SOURCE]: MP_VALUES.STYLIST_PROFILE_PAGE
            }
        });

        navigate(`/stylist/${uuid}/profile`);
    };

    const onViewMoreClick = () => {
        trackEvent({
            name: MP_EVENTS.VIEW_MORE_STYLISTS_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.STYLIST_PROFILE_PAGE
            }
        });
        navigate(`/stylistSearch/`);
    };

    if (!user) {
        return <></>;
    }

    const isFavoriteStylist = (stylistId) => favoriteStylists.find(
        (favorite) => favorite.uuid === stylistId
    )
    const getStylistCard = (stylist) => {
        const isFavorite = isFavoriteStylist(stylist.uuid);

        return (
            <Col className="match-item-container" xs={12} md={4} key={stylist.uuid}>
                <MatchItem
                    match={stylist}
                    onClick={selectStylist}
                    onFavorite={isFavorite ? setUnfavoriteStylist : setFavoriteStylist}
                    isFavorite={isFavorite}
                />
            </Col>
        );
    }
    
    const otherMatches = stylistsMatchs.filter(({uuid})=> uuid !== stylist.uuid);

    const otherMatchesComponent = (stylists) => {
        return (
            <>
                <div className="title">{Content.stylist.matches}</div>
                <Row className="matches">
                    {stylists.map((match) => getStylistCard(match))}
                </Row>
            </>
        );
    }

    return (
        <div className="matches-section">
            {otherMatches.length > 0 && otherMatchesComponent(otherMatches)}
            <Row>
                <Col className="button-container">
                    <button
                        className="more-btn"
                        onClick={onViewMoreClick}
                        data-test-id="load-more">
                        {Content.match.more}
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default Matches;

import React, { FC, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import content from 'content.json';
import { Payments } from 'services';
import { MP_EVENTS, trackEvent } from 'services/mixpanel';
import { getChatUrl } from 'services/utils/url-utils';
import { useUserId } from 'store/auth-service';
import { useGetStylingSessionCheckoutQuery } from 'store/payments/paymentsApiSlice';
import { StylingSessionGoal, StylingSessionPlan, StylingSessionType } from 'store/payments/types';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';

import { Loader, Page } from 'components';

import { SessionTypeSelector } from './SessionTypeSelector';

const stripePromise = loadStripe(Payments.getStripeKey() as string);

const {
    pricing: { plans }
} = content;

export const StylingSessionCheckout: FC = () => {
    const navigate = useNavigate();
    const userId = useUserId();
    const [searchParams] = useSearchParams();
    const stylistId = searchParams.get('stylistId');
    const goal = searchParams.get('goal') as StylingSessionGoal;
    const plan = searchParams.get('plan') as StylingSessionPlan;
    const [sessionType, setSessionType] = useState(plan === 'lux' ? 'one-time' : 'subscription');
    const { price: planPrice, title: planTitle } = plans.find(({ value }) => value === plan) || {};
    const hasAllProps = stylistId && goal && plan && planPrice;
    const { data: stylistData } = useGetStylistQuery(stylistId!, { skip: !hasAllProps });
    const isActiveStylist = stylistData?.is_active;
    const { data: stylingSession, isFetching: isFetchingStylingSessionCheckout } =
        useGetStylingSessionCheckoutQuery(
            {
                stylistUuid: stylistId!,
                type: sessionType as StylingSessionType,
                plan,
                returnUrl: `/checkout/confirmation?stylistId=${stylistId}&requestId={REQUEST_ID}`
            },
            { skip: !(hasAllProps && isActiveStylist), refetchOnMountOrArgChange: true }
        );

    useEffect(() => {
        trackEvent({
            name: MP_EVENTS.CHECKOUT_VIEWS
        });
    }, []);

    if (!stylistData) {
        return <Loader />;
    }

    if (stylistData?.has_active_session) {
        return <Navigate to={getChatUrl(stylistData.uuid, userId!)} />;
    }

    if (hasAllProps && isActiveStylist) {
        return (
            <Page footer={false} header={false} className="styling-session-checkout-page">
                {isFetchingStylingSessionCheckout && <Loader />}
                <div className="header">
                    <div className="back-btn" onClick={() => navigate(-1)} />
                    <div className="stylist-image">
                        <img src={stylistData.profile_picture} alt={stylistData.name} />
                    </div>
                    <div className="stylist-name">{stylistData.name}</div>
                </div>
                {plan === 'lux' ? (
                    <div className="subtitle">Wishi Lux Package</div>
                ) : (
                    <>
                        <div className="subtitle">How often would you like a styling session?</div>
                        <div className="session-type">
                            <SessionTypeSelector
                                value="subscription"
                                selection={sessionType}
                                onSelect={setSessionType}
                                label={
                                    <>
                                        <b>
                                            <span>Monthly Membership</span>
                                            <span>${planPrice.monthly}</span>
                                        </b>
                                        <i>3-Day Free Trial</i>
                                    </>
                                }
                            />
                            <SessionTypeSelector
                                value="one-time"
                                selection={sessionType}
                                onSelect={setSessionType}
                                label={
                                    <b>
                                        <span>One-time {planTitle}</span>
                                        <span>${planPrice.onetime}</span>
                                    </b>
                                }
                            />
                        </div>
                    </>
                )}
                {stylingSession?.secret && !isFetchingStylingSessionCheckout && (
                    <EmbeddedCheckoutProvider
                        stripe={stripePromise}
                        /* Key is needed to trigger Stripe to rerender the checkout form on session type change. Do not remove it! */
                        key={stylingSession.secret}
                        options={{ clientSecret: stylingSession.secret }}>
                        <EmbeddedCheckout className="stripe-checkout-container" />
                    </EmbeddedCheckoutProvider>
                )}
            </Page>
        );
    }

    return <Navigate to="/" />;
};

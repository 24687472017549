import './style.scss';

import campaigns from 'campaigns.json';
import { useWithDispatch } from 'hooks';
import React, { useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { KlaviyoEvent, klaviyoTrack } from 'services/Klaviyo';
import { selectPlan } from 'store/booking/actions';
import { useBookingStore } from 'store/booking/reducer';
import { Plan as PlanType } from 'store/booking/types';
import { usePaymentsStore } from 'store/payments/reducer';
import { useUserStore } from 'store/user/reducer';

import content from 'content.json';
import { RadioButton } from '../';
import Plan from './Plan';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';

const { pricing } = content;

interface IPlans {
    plans: PlanType[];
    recommended?: any | null;
    onSelect?: (_plan: PlanType) => void;
}
const Plans: React.FC<IPlans> = ({ plans = [], recommended = null, onSelect }) => {
    const navigate = useNavigate();
    const goal = useBookingStore((store) => store.goal);
    const [slide, setSlide] = useState(goal && goal.value === 'mini' ? 0 : 1);
    const user = useUserStore((store) => store.user);
    const campaign = useBookingStore((store) => store.campaign);
    const stylist = useBookingStore((store) => store.stylist);
    const model = usePaymentsStore((store) => store.model);
    const sliderRef = useRef<Slider>(null);
    const slideUpdate = (index: number) => {
        if (sliderRef?.current) sliderRef.current.slickGoTo(index);
    };
    const selectPlanAction = useWithDispatch(selectPlan);
    const togglePlans = (index: number) => {
        if (slide !== index) {
            setSlide(index);
            slideUpdate(index);
        }
    };

    const trackStylingClick = () =>
        trackEvent({
            name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: MP_VALUES.PRICING_PAGE,
                [MP_PROPS.ELEMENT]: 'pricing details'
            }
        });
        
    const onPlanSelect = (plan: PlanType) => {
        klaviyoTrack(KlaviyoEvent.PLAN_CLICK, { plan: plan.value });
        if (!user) {
            trackStylingClick();
        }
        if (stylist?.uuid) {
            onSelect ? onSelect(plan) : selectPlanAction(plan);
        } else {
            const to = user ? '/stylistSearch' : '/onboarding/';
            navigate(to);
        }
    };

    return (
        <Container className="plans">
            <Row className="d-none d-md-flex">
                {plans.map((plan) => (
                    <Col key={plan.value} className="plan-container">
                        <Plan
                            buttonText={goal ? plan.button : pricing.button}
                            price={plan.price[model]}
                            plan={plan}
                            model={model}
                            highlighted={
                                recommended ? plan.value === recommended : plan.value === 'mini'
                            }
                            isRecommended={plan.value === recommended}
                            onSelect={onPlanSelect as any}
                            button={recommended && plan.value !== recommended ? 'light' : 'dark'}
                            celebrity={stylist?.is_celebrity}
                            campaign={campaign && campaigns[campaign]}
                        />
                    </Col>
                ))}
            </Row>
            <div className="d-block d-md-none">
                <Slider
                    ref={sliderRef}
                    arrows={false}
                    speed={500}
                    infinite={false}
                    slidesToShow={1}
                    slidesToScroll={1}
                    afterChange={setSlide}
                    initialSlide={slide}>
                    {plans.map((plan) => (
                        <div key={plan.value}>
                            <Plan
                                plan={plan}
                                model={model}
                                highlighted={
                                    recommended ? plan.value === recommended : plan.value === 'mini'
                                }
                                isRecommended={plan.value === recommended}
                                onSelect={onPlanSelect as any}
                                button={
                                    recommended && plan.value !== recommended ? 'light' : 'dark'
                                }
                                campaign={campaign && campaigns[campaign]}
                            />
                        </div>
                    ))}
                </Slider>
                {plans.length > 1 && (
                    <div className="controls">
                        {plans.map((plan, index) => (
                            <RadioButton
                                type="dark"
                                direction="vertical"
                                key={plan.value}
                                id={`${plan.value}Goal`}
                                value={plan.value}
                                isChecked={slide === index}
                                onClick={() => togglePlans(index)}
                                label={plan.title}
                            />
                        ))}
                    </div>
                )}
            </div>
        </Container>
    );
};

export default Plans;

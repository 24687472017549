import { rootSplitApi } from '../root-api-slice';

import {
    CompleteOrderRequest,
    CompleteStylingSessionCheckoutRequest,
    GetExpressCheckoutPaymentIntentRequest,
    GetExpressCheckoutPaymentIntentResponse,
    GetItemCheckoutSessionRequest,
    GetItemCheckoutSessionResponse,
    GetOrderDetailsRequest,
    GetOrderDetailsResponse,
    GetPriceWithTaxRequest,
    GetPriceWithTaxResponse,
    GetStylingSessionCheckoutDetailsRequest,
    GetStylingSessionCheckoutDetailsResponse,
    GetStylingSessionCheckoutRequest,
    GetStylingSessionCheckoutResponse
} from './types';

const paymentsApi = rootSplitApi
    .enhanceEndpoints({
        addTagTypes: [
            'ItemsCheckoutSession',
            'Order',
            'ExpressCheckoutPaymentIntent',
            'Taxes',
            'StylingSessionCheckout'
        ]
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getItemsCheckoutSession: build.query<
                GetItemCheckoutSessionResponse,
                GetItemCheckoutSessionRequest
            >({
                query: ({ items, returnUrl }) => ({
                    url: '/session-checkout',
                    method: 'POST',
                    body: {
                        items,
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['ItemsCheckoutSession']
            }),
            getPriceWithTax: build.query<GetPriceWithTaxResponse, GetPriceWithTaxRequest>({
                query: (body) => ({
                    url: '/express-checkout/calculate-taxes',
                    method: 'POST',
                    body
                }),
                providesTags: ['Taxes']
            }),
            getExpressCheckoutPaymentIntent: build.query<
                GetExpressCheckoutPaymentIntentResponse,
                GetExpressCheckoutPaymentIntentRequest
            >({
                query: (body) => ({
                    url: '/express-checkout',
                    method: 'POST',
                    body
                }),
                providesTags: ['ExpressCheckoutPaymentIntent']
            }),
            getOrderDetails: build.query<GetOrderDetailsResponse, GetOrderDetailsRequest>({
                query: (params) => ({
                    url: '/checkout',
                    method: 'GET',
                    params
                }),
                providesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }]
            }),
            completeOrder: build.mutation<void, CompleteOrderRequest>({
                query: (body) => ({
                    url: '/complete',
                    method: 'POST',
                    body
                }),
                invalidatesTags: (result, error, { orderId }) => [{ type: 'Order', id: orderId }]
            }),
            getStylingSessionCheckout: build.query<
                GetStylingSessionCheckoutResponse,
                GetStylingSessionCheckoutRequest
            >({
                query: ({ returnUrl, ...body }) => ({
                    url: '/styling-session/session-checkout',
                    method: 'POST',
                    body: {
                        ...body,
                        returnUrl: `${location.origin}${returnUrl}`
                    }
                }),
                providesTags: ['StylingSessionCheckout']
            }),
            getStylingSessionCheckoutDetails: build.query<
                GetStylingSessionCheckoutDetailsResponse,
                GetStylingSessionCheckoutDetailsRequest
            >({
                query: (params) => ({
                    url: '/styling-session',
                    method: 'GET',
                    params
                }),
                providesTags: ['StylingSessionCheckout']
            }),
            completeStylingSessionCheckout: build.mutation<
                void,
                CompleteStylingSessionCheckoutRequest
            >({
                query: (body) => ({
                    url: '/styling-session/complete',
                    method: 'POST',
                    body
                }),
                invalidatesTags: ['StylingSessionCheckout']
            })
        })
    });

export const {
    useGetItemsCheckoutSessionQuery,
    useGetOrderDetailsQuery,
    useLazyGetExpressCheckoutPaymentIntentQuery,
    useLazyGetPriceWithTaxQuery,
    useCompleteOrderMutation,
    useGetStylingSessionCheckoutQuery,
    useGetStylingSessionCheckoutDetailsQuery,
    useCompleteStylingSessionCheckoutMutation
} = paymentsApi;

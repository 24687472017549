import './style.scss';

import { Loader, Page } from 'components';
import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Stylist, Tracking } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { setCampaignBooking } from 'store/booking/actions';
import { useCommonStore } from 'store/common/reducer';
import { useUserStore } from 'store/user/reducer';
import { Stylist as StylistType } from 'types/user';

import Banner from './components/Banner';
import { List } from './components/List';
import { sliceArrayByThree } from './utils/stylists-utils';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';

export const StylistsList: React.FC = () => {
    const location = useLocation();
    const [showBanner, setShowBanner] = useState(true);
    const [stylistMatches, setStylistMatches] = useState<any[]>([]);
    const [moreStylists, setMoreStylists] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const mixpanelStore = useCommonStore((store) => store.mixpanelStore);
    const {data: matches = []} = useBestMatchesQuery();
    const user = useUserStore((store) => store.user);
    const setCampaignBookingAction = useWithDispatch(setCampaignBooking);

    const analyticsTrackMatchPageEvents = () => {
        Tracking.tag({
            event: 'eec.impressionView',
            ecommerce: {
                impressions: matches.map((match, index) => ({
                    id: match.uuid,
                    name: match.name,
                    category: '/category/stylist/',
                    list: 'Stylist Recommendations',
                    position: index
                }))
            }
        });
    };
    const mixpanelTrackMatchPageEvents = () => {
        trackEvent({
            name: MP_EVENTS.STYLIST_MATCH_VIEWS,
            properties: {
                [MP_PROPS.PREFERRED_STYLES]: mixpanelStore?.onboarding?.length
                    ? mixpanelStore.onboarding[MP_PROPS.PREFERRED_STYLES]
                    : '',
                'Stylist match names': matches.reduce((acc, curr) => {
                    if (curr?.name) {
                        acc.push(curr.name);
                    }
                    return acc;
                }, [] as string[]),
                'Stylist match UUIDs': matches.reduce((acc, curr) => {
                    if (curr?.uuid) acc.push(curr.uuid);
                    return acc;
                }, [] as string[])
            }
        });
    };

    useEffect(() => {
        setCampaignBookingAction();
    }, []);

    useEffect(() => {
        // @ts-ignore
        setCampaignBookingAction(location.state ? location.state.campaign : null);
    }, [location]);

    useEffect(() => {
        if (matches?.length) {
            setStylistMatches(matches);
            analyticsTrackMatchPageEvents();
            mixpanelTrackMatchPageEvents();
            loadMore(matches);
        }
    }, [matches]);

    const loadMore = async (initMatches: StylistType[]) => {
        try {
            setLoading(true);
            const { data } = await Stylist.all({ count: 15, user_gender: user?.gender });
            const stylistItemsArray = Array.isArray(data.items)
                ? data.items
                : Object.values(data.items);
            const stylistsList = [
                ...stylistItemsArray.filter(
                    (obj: StylistType) =>
                        initMatches.findIndex((match) => match.uuid === obj.uuid) < 0 &&
                        !obj.picture
                )
            ];
            const slicedArray = stylistsList.length <= 3 ? stylistsList : sliceArrayByThree(stylistsList);
            setMoreStylists(slicedArray);
            setLoading(false);
        } catch (e) {
            setLoading(false);
        }
    };
    return (
        <Page footer={false} className="no-padding">
            <Container className="match" fluid>
                {loading && <Loader />}
                {showBanner && <Banner onClose={() => setShowBanner(false)} />}
                <Row>
                    <Col className="results">
                        <Container>
                            <List matchedStylists={stylistMatches} moreStylists={moreStylists} />
                        </Container>
                    </Col>
                </Row>
            </Container>
        </Page>
    );
};

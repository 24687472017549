import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useIsLoggedIn } from 'store/auth-service';

export const ProtectedRoute: React.FC<{ redirectPath?: string }> = ({ redirectPath = '/' }) => {
    const isLoggedIn = useIsLoggedIn();
    const location = useLocation();

    if (!isLoggedIn) {
        return <Navigate to={redirectPath} replace state={{ from: location }} />;
    }
    return <Outlet />;
};

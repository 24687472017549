import { useLayoutEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const debouncedUpdateSize = useRef(
        debounce(() => {
            setIsMobile(window.innerWidth < 768);
        }, 250)
    ).current;

    useLayoutEffect(() => {
        const updateSize = (): void => {
            debouncedUpdateSize();
        };
        window.addEventListener('resize', updateSize);
        return (): void => {
            window.removeEventListener('resize', updateSize);
            debouncedUpdateSize.cancel();
        };
    }, [debouncedUpdateSize]);

    return isMobile;
};

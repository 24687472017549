import axios from 'axios';

import Config from './Config';
import { getUserId, getUserToken } from 'services/utils/user-utils';

const proxyRoot = Config.get('apiroot2');
const gwRoot = Config.get('api-gw');

const Style = {
    getMoodBoards: (gender) =>
        axios.get(`${proxyRoot}user/onboarding/moodboards`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    getBodyTypes: (gender) =>
        axios.get(`${proxyRoot}tag/active`, {
            params: {
                type: 'body',
                gender
            },
            headers: { token: getUserToken() }
        }),

    updateStyle: (user_id, style) =>
        axios.put(
            `${proxyRoot}user/${user_id}/stylingPreferences`,
            { ...style },
            { headers: { token: getUserToken() } }
        ),

    getFeed: (params) =>
        axios.get(`${proxyRoot}feed/all`, {
            params: { ...params, types: 'styled_you' }
        }),

    getOutfit: (outfit_uuid) => 
        axios.get(`${proxyRoot}outfit/web/${outfit_uuid}/${getUserId() ?? 'null'}`),

    getItem: (item_uuid) =>
        axios.get(`${proxyRoot}item/catalog/${item_uuid}`, { params: { user_uuid: getUserId() } }),
    
    getSizes: (item_uuid) =>
        axios.get(`${proxyRoot}item/${item_uuid}/availableSizes`, {
            headers: { token: getUserToken() }
        }),

    filters: () => axios.get(`${Config.get('apiroot2')}giftItem/filters?client=web`),
    giftItems: (filters) => axios.get(`${Config.get('apiroot2')}giftItem`, { params: filters }),
};

export default Style;

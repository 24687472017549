import { me, rootSplitApi } from 'store/root-api-slice';
import { CoreStylist, StylistUser } from 'types/stylist';
import { GetReviewsRequest, LabelValuePair, NewReviewBody, ReviewUser, ReviewUserResponse, StylistReview, StylistReviewResponse } from './stylist-api-types';

function providesList<R extends { id: string | number }[], T extends string>(
	resultsWithIds: R | undefined,
	tagType: T
) {
	return resultsWithIds
		? [{ type: tagType, id: 'LIST' }, ...resultsWithIds.map(({ id }) => ({ type: tagType, id }))]
		: [{ type: tagType, id: 'LIST' }];
}

const transformReviewUser = (user: ReviewUserResponse) : ReviewUser => ({
	id: user.uuid,
	firstName: user.first_name,
	lastName: user.lastName,
	picture: user.picture,
	pictureLarge: user.picture_large,
	pictureMedium: user.picture_medium,
	pictureSmall: user.picture_small
});

const transformStylistReview = (review: StylistReviewResponse, stylistId: string) : StylistReview => ({
	...review,
	id: review.user.uuid + review.created,
	stylistId,
	created: new Date(review.created),
	title: review.title ?? undefined,
	user: transformReviewUser(review.user)
});

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Stylist', 'Reviews', 'AllStylistNames'] });

export const stylistApi = apiWithTag.injectEndpoints({
	endpoints: (build) => ({
		getAllStylistNames: build.query<LabelValuePair[], void>({
			query: () => '/proxy/stylist/all/names',
			providesTags: ['AllStylistNames'],
			transformResponse: (response: CoreStylist[]) => response.map((stylist) => ({
                value: stylist.stylist_uuid,
                label: stylist.stylist_name
			}))
		}),

		getStylist: build.query<StylistUser, string>({
			query: (stylistId) => ({
				url: `proxy/stylist/${stylistId}/read`,
				method: 'GET',
                params: { user_uuid: me }
			}),
			providesTags: (result) => [{ type: 'Stylist', id: result?.uuid }],
		}),

		getStylistReviews: build.query<StylistReview[], GetReviewsRequest>({
			query: ({stylistId, from = 0, count = 5}) => ({
				url: `proxy/stylist/${stylistId}/reviews`,
				method: 'GET',
                params: { from, count }
			}),
			providesTags: (result) => providesList(result, 'Reviews'),
			transformResponse: (response: StylistReviewResponse[], meta, arg) => 
				response.map((review) => transformStylistReview(review, arg.stylistId)),
		}),

		addStylistReview: build.mutation<void, { stylistId: string, review: NewReviewBody }>({
			query: ({ stylistId, review }) => ({
				url: `proxy/stylist/${stylistId}/review`,
				method: 'POST',
				body: {
					...review,
				}
			}),
			invalidatesTags: [{ type: 'Reviews', id: 'LIST' }],
		}),
	})
});


export const { 
	useGetAllStylistNamesQuery,
	useGetStylistQuery, 
	useGetStylistReviewsQuery, 
	useAddStylistReviewMutation } = stylistApi;
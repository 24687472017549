import './style.scss';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { LdEvent } from 'services/LaunchDarkly';
import { ErrorState } from 'store/error/types';
import { Client } from 'types/user';
import { BodyType, OnBoarding, ValidationError } from 'types/utils';

import content from 'content.json';
import { useWithDispatch } from 'hooks';
import { Validator } from 'services';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { toggleModal } from 'store/ui/actions';
import { GoogleLogin } from 'components/GoogleLogin/GoogleLogin';
import Loader from 'components/Loader';
import { getPageType } from 'services/utils/url-utils';

const { modal } = content;

const getDefaultRedirectUrl = (path: string) => {
    switch (path) {
        case '/onboarding/style':
            return '/stylistSearch';
        case '/feed':
            return '/feed';
        case '/gifts/shopping':
            return '/gifts/shopping';
        default:
            return '/onboarding';
    }
};

interface ISignup {
    changeModal: (type: string) => void;
    signup: (credentials: any, trackLdEvent: (event: LdEvent) => void) => void;
    error: ErrorState;
    resetErrors: () => void;
    modalType: string;
    user: Client;
    onboarding: OnBoarding;
    redirectUrl: string | null;
    data: { source: string; element: string; onSuccess?: () => void };
}

const Signup: React.FC<ISignup> = ({
    changeModal,
    signup,
    error,
    resetErrors,
    modalType,
    user,
    onboarding,
    redirectUrl,
    data: modalData
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState<ValidationError>({});
    const texts = modal.signup;
    const ldClient = useLDClient();
    const toggleModalAction = useWithDispatch(toggleModal);

    const validationErrors = useMemo(() => {
        const result: Record<string, string> = {};
        error.errors.forEach(({ field, message }) => {
            result[field] = message;
        });

        return result;
    }, [error]);

    const validate = () => {
        let errors = {};

        errors = {
            ...Validator.name(name),
            ...Validator.email(email),
            ...Validator.password(password)
        };

        if (Object.keys(errors).length) {
            setErrors(errors);
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    const validateOnBlur = (field: string, value: string) => {
        let errors = {};
        switch (field) {
            case 'name':
                errors = { ...Validator.name(value) };
                if (Object.keys(errors).length === 0) errors = { name: null, lastname: null };
                break;
            case 'email':
                errors = { ...Validator.email(value) };
                if (Object.keys(errors).length === 0) errors = { email: null };
                break;
            case 'password':
                errors = { ...Validator.password(value) };
                if (Object.keys(errors).length === 0) errors = { password: null };
                break;
        }

        setErrors((preErrors: ValidationError) => ({ ...preErrors, ...errors }));
    };

    const getCredentials = (fbData?: any) => {
        let credentials = fbData
            ? fbData
            : {
                  email,
                  full_name: name,
                  first_name: name.split(' ')[0],
                  last_name: name.split(' ')[1],
                  password
              };

        if (modalType === 'Unlock') {
            const { gender, styles, bodyTypes } = onboarding;
            credentials = {
                ...credentials,
                gender,
                styles,
                tags: bodyTypes.map((type: BodyType) => type.tag_uuid).join(',')
            };
        }
        return credentials;
    };
    const trackLdEvent = (event: LdEvent) => {
        if (ldClient) {
            ldClient.track(event);
        }
    };
    const submit = async (fbData?: any) => {
        if (!loading) {
            setLoading(true);
            await signup(getCredentials(fbData), trackLdEvent);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (user) {
            toggleModalAction({ type: null });
            if (modalData?.onSuccess) {
                modalData.onSuccess();
            } else if (user.new_user && modalType !== 'Unlock') {
                const { state, pathname } = location;
                const path = redirectUrl ? redirectUrl : getDefaultRedirectUrl(pathname);

                if (state?.from && state?.currentPath) {
                    if (state.from !== path && state.currentPath !== path) {
                        navigate(path, {
                            state: {
                                from: pathname,
                                currentPath: path
                            }
                        });
                    }
                } else {
                    navigate(path, {
                        state: {
                            from: pathname,
                            currentPath: path
                        }
                    });
                }
            }
        }
    }, [email, user, redirectUrl, location, navigate, toggleModalAction]);

    useEffect(() => resetErrors(), [resetErrors]);

    useEffect(() => {
        modalData &&
            trackEvent({
                name: MP_EVENTS.SIGNUP_PAGE_VIEW,
                properties: {
                    [MP_PROPS.SOURCE]: modalData.source || getPageType() || '',
                    [MP_PROPS.ELEMENT]: modalData.element || ''
                }
            });
    }, [modalData]);

    const signupMarkerClass = 'signup-modal-button';

    return (
        <div className="signup-modal">
            <div className="logo" />
            <Modal.Header closeButton={true}>
                <div className="title">{modalType === 'Unlock' ? texts.unlock : texts.title}</div>
                <div className="subtitle">{texts.subtitle}</div>
            </Modal.Header>

            <GoogleLogin disabled={loading} onSuccess={submit} markerClass={signupMarkerClass} />

            <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                    e.preventDefault();
                    if (validate()) {
                        submit();
                    }
                }}>
                <div className="input-container">
                    <input
                        data-test-id="signup-name"
                        name="name"
                        type="text"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setName(e.target.value)
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            validateOnBlur('name', e.target.value)
                        }
                        placeholder={texts.name}
                        required
                    />
                    {(errors.name || errors.lastname) && (
                        <div className="validation-error">{errors.name || errors.lastname}</div>
                    )}

                    {validationErrors.first_name && (
                        <div className="validation-error">{validationErrors.first_name}</div>
                    )}
                    {validationErrors.last_name && (
                        <div className="validation-error">{validationErrors.last_name}</div>
                    )}
                </div>

                <div className="input-container">
                    <input
                        data-test-id="signup-email"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setEmail(e.target.value)
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            validateOnBlur('email', e.target.value)
                        }
                        placeholder={texts.email}
                        required
                    />
                    {errors.email && <div className="validation-error">{errors.email}</div>}
                    {validationErrors.email && (
                        <div className="validation-error">{validationErrors.email}</div>
                    )}
                </div>

                <div className="input-container">
                    <input
                        data-test-id="signup-password"
                        name="password"
                        type="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setPassword(e.target.value)
                        }
                        onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
                            validateOnBlur('password', e.target.value)
                        }
                        placeholder={texts.password}
                        required
                    />
                    {errors.password && <div className="validation-error">{errors.password}</div>}
                    {validationErrors.password && (
                        <div className="validation-error">{validationErrors.password}</div>
                    )}
                </div>

                {/*{error && error.message && <div className="validation-error">{error.message}</div>}*/}

                <div className="legal" dangerouslySetInnerHTML={{ __html: texts.terms }} />

                <button
                    id="submit"
                    type="submit"
                    className={`submit-button ${signupMarkerClass}`}
                    data-test-id="signup-button"
                    disabled={!name || !email || !password || loading}>
                    {texts.signup}
                </button>
            </form>

            <div className="existing-user-message">
                {texts.account}&nbsp;
                <strong onClick={() => changeModal('Signin')}>{texts.signin}</strong>
            </div>
            {loading && <Loader />}
        </div>
    );
};

export default Signup;

import { useWithDispatch } from 'hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Style } from 'services';
import {
    MP_EVENTS,
    MP_EXP_VARIANTS,
    MP_PROPS,
    setProfileProps,
    trackEvent
} from 'services/mixpanel';
import { setMixpanelStore } from 'store/common/actions';
import { toggleModal } from 'store/ui/actions';
import { updateUser } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';

import { useBookingStore } from 'store/booking/reducer';
import { TOnboardingState, TStyleId } from './types';
import { useLazyBestMatchesQuery } from 'store/user-service/user-api-slice';

export const useOnboardingComplete = () => {
    const isCompletedOnce = useRef(false);
    const deferredCompleteState = useRef<TOnboardingState>();
    const { user } = useUserStore((store) => store);
    const { stylist } = useBookingStore((state) => state);
    const toggleModalAction = useWithDispatch(toggleModal);
    const updateUserAction = useWithDispatch(updateUser);
    const setMixpanelStoreAction = useWithDispatch(setMixpanelStore);
    const navigate = useNavigate();
    const { skipStylistMatch } = useFlags();
    const [ getBestMatches, { data: bestMatches = [] } ] = useLazyBestMatchesQuery();
    const userId = user?.user_uuid;
    const isIdentified = !!userId; //&& ldUser && !ldUser.anonymous;

    // This function will run once on completion of onboarding
    // This is to prevent consecutive reporting on possible history.back or deferred completion
    const trackCompletionEvents = useCallback(
        (styles: [TStyleId, number][]) => {
            if (!isCompletedOnce.current && styles.length) {
                const stylePreferredList = styles
                    .filter(([, value]) => value === 2)
                    .map(([name]) => name);

                setMixpanelStoreAction({
                    onboarding: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                trackEvent({
                    name: MP_EVENTS.MEET_MY_MATCH,
                    properties: { [MP_PROPS.PREFERRED_STYLES]: stylePreferredList }
                });

                isCompletedOnce.current = true;
            }
        },
        [setMixpanelStoreAction]
    );

    const getRedirectUrl = useCallback(async () => {
        let result = '/';

        if (isIdentified) {
            // This flow is for the Stylist page -> [Book Stylist] -> Onboarding
            // Stylist is set by booking a stylist.
            if (stylist?.uuid) {
                if (stylist.has_active_session) {
                    result = `/chat/${stylist.uuid}/${userId}`;
                } else {
                    result = '/goals';
                }
            } else {
                let variant = MP_EXP_VARIANTS.CONTROL;
                result = '/stylistSearch';

                if (skipStylistMatch && Math.random() > 0.5) {
                    await getBestMatches();
                    const bestMatch = bestMatches
                        .sort((a, b) => b.match_percentage - a.match_percentage)
                        .shift();

                    if (bestMatch) {
                        result = `/stylist/${bestMatch.uuid}/profile`;
                        variant = MP_EXP_VARIANTS.STYLIST_PROFILE;
                    }
                }

                setProfileProps({ [MP_PROPS.EXP_DIRECT_MATCH]: variant });
            }
        }

        return result;
    }, [isIdentified, userId, stylist, skipStylistMatch]);

    const complete = useCallback(
        async (onboardingState: TOnboardingState) => {
            const { gender, bodyTypes, styles: stylesMap, source } = onboardingState;
            const styles = [...(stylesMap || [])];

            trackCompletionEvents(styles);

            if (isIdentified) {
                const tags = [...(bodyTypes?.values() || [])].map(({ uuid, name }) => ({
                    tag_uuid: uuid,
                    is_set: 1,
                    name
                }));

                await Style.updateStyle(userId, {
                    gender,
                    tag_uuids: tags,
                    styles: styles.map(([name, rate]) => ({ name, rate }))
                });

                updateUserAction({ gender }); // This is to allow gendered stylist matches without re-fetching the user
                navigate(await getRedirectUrl());
            } else {
                deferredCompleteState.current = onboardingState; // Save the completed onboarding state for when the user is available

                toggleModalAction({
                    type: 'Unlock',
                    data: { source, element: 'take quiz click'}
                });
            }
        },
        [
            getRedirectUrl,
            navigate,
            isIdentified,
            toggleModalAction,
            trackCompletionEvents,
            updateUserAction,
            userId
        ]
    );

    useEffect(() => {
        if (deferredCompleteState.current && isIdentified) {
            const deferredState = { ...deferredCompleteState.current };
            deferredCompleteState.current = undefined;
            complete(deferredState);
        }
    }, [complete, isIdentified]);

    return complete;
};

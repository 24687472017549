import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { Collage, ImagesCarousel, StylingButton } from 'components';
import { MP_VALUES } from 'services/mixpanel';

const tracking = {
    source: MP_VALUES.LANDING_PAGE,
    element: 'landing page hero'
}

export interface HeaderProps {
    data: {
        logo: {
            black: string;
            white: string;
        };
        title: string;
        text: string;
        button: {
            text: string;
        };
        more: string;
        web: {
            src: string;
            fallback: string;
        }[];
        mobile: string[];
    };
}

const Header: React.FC<HeaderProps> = ({ data }) => (
    <Row className="landing-header">
        <Col xs={12} className="d-none d-sm-block no-padding">
            <Container fluid>
                <Row>
                    <Col xs={7}>
                        <img className="wishi-logo" src={data.logo.black} alt="wishi" />
                        <h2>{data.title}</h2>
                        <h4>{data.text}</h4>
                        <StylingButton text={data.button.text} tracking={tracking}/>
                        <div className="more d-none d-sm-block">{data.more}</div>
                    </Col>
                    <Col xs={5}>
                        <Collage images={data.web} />
                    </Col>
                </Row>
            </Container>
        </Col>
        <Col xs={12} className="d-block d-sm-none no-padding">
            <Container className="mobile-collage-overlay">
                <Row>
                    <Col>
                        <img className="wishi-logo" src={data.logo.white} alt="wishi" />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <p>{data.title}</p>
                    </Col>
                </Row>
            </Container>

            <ImagesCarousel items={data.mobile} />
            <div className="mobile-sticky">
                <StylingButton tracking={tracking}/>
            </div>
        </Col>
    </Row>
);

export default Header;

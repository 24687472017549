import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { MP_EVENTS, MP_VALUES, MP_PROPS, trackEvent } from 'services/mixpanel';
import { getOutfitProperties } from 'services/mixpanel/utils';
import { getItemType, isExternalItem, isShopableItem } from 'services/utils/item-utils';

import { FavoriteButton } from 'components';
import content from 'content.json';
import { Formatter } from 'services';
import { useLocation } from 'react-router-dom';
import { useFavoriteLookMutation, useUnfavoriteLookMutation } from 'store/user-service/user-api-slice';

const { feed } = content;

export default ({
    user,
    outfit,
    isFavorite = false,
    addToCart,
    removeFromCart,
    onItemSelect,
    toggleModal,
    updateStylistLook,
    isMainOutfit = false,
    currentOutfit,
    setCurrentOutfit,
    outfitSource = null
}) => {
    const location = useLocation();
    const [current, setCurrent] = useState({});
    const isMobile = useIsMobile();
    const [ favoriteLook ] = useFavoriteLookMutation();
    const [ unfavoriteLook ] = useUnfavoriteLookMutation();

    useEffect(() => {
        if ((!current.unique && outfit.unique) || (!current.uuid && outfit.uuid)) {
            setCurrent(outfit);
        }
        if (outfit?.items && outfitSource && isMainOutfit && currentOutfit !== outfit.uuid) {
            setCurrentOutfit(outfit.uuid);
            trackEvent({
                name: MP_EVENTS.LOOK_VIEWS,
                properties: getOutfitProperties(outfit, outfitSource)
            });
        }
    }, [outfit, current, outfitSource]);

    const onCartClick = (item) => {
        if (!user) {
            toggleModal({
                type: 'Signup',
                url: location.pathname,
                data: { 
                    source: MP_VALUES.OUTFIT_PAGE,
                    element: MP_VALUES.CART_CLICK 
                }
            });
        } else {
            if (!item.is_in_cart) {
                addToCart(item, outfitSource);
            } else {
                removeFromCart(item);
            }

            const newItems = current.items.map((i) =>
                i.unique === item.unique ? { ...item, is_in_cart: !item.is_in_cart } : i
            );

            setCurrent({ ...current, items: newItems });
            updateStylistLook(outfit.unique, current);
        }
    };
    
    const onFavoriteLookClick = () => {
        if (isFavorite) {
            unfavoriteLook({userId: user.user_uuid, lookId: current.uuid});
        } else {
            favoriteLook({userId: user.user_uuid, lookId: current.uuid});
            trackEvent({
                name: MP_EVENTS.LOOK_ADDED_TO_FAVORITE,
                properties: {
                    [MP_PROPS.LOOK_UUID]: favoriteOutfit.uuid,
                    [MP_PROPS.LOOK_SOURCE]: outfitSource,
                    [MP_PROPS.STYLIST_NAME]:
                        outfitSource == 'favorites'
                            ? outfit.owner.name
                            : outfit.owner.first_name.concat(' ', outfit.owner.last_name),
                    [MP_PROPS.STYLIST_UUID]: outfit.owner.uuid
                }
            });
        }
    };

    return (
        <div className="outfit">
            <Col xs={12} md={6} className="look">
                {current.attributes?.name ? (
                    <p>{current.attributes.name}</p>
                ) : (
                    current.outfit_name && <p>{current.outfit_name}</p>
                )}
                <FavoriteButton
                    isFavorite={isFavorite}
                    onClick={onFavoriteLookClick}
                />
                <img className="outfit-image" src={outfit.picture} alt="Outfit" />
            </Col>
            {current.items?.length && (
                <Col
                    xs={12}
                    md={6}
                    className={`items ${current.items.length <= 4 ? 'no-scroll' : ''}`}>
                    <Container fluid={isMobile}>
                        <p className="shop-board d-block d-sm-none">{feed.board}</p>
                        <Row>
                            {current.items.map((item, index) => (
                                <Col xs={6} key={index} className={getItemType(item)}>
                                    {isShopableItem(item) && !item.is_in_closet && (
                                        <div
                                            className={`cart-icon ${
                                                item.is_in_cart ? 'added' : 'add'
                                            }`}
                                            onClick={() => onCartClick(item)}
                                        />
                                    )}
                                    <div
                                        className="item"
                                        onClick={() =>
                                            isShopableItem(item) ? onItemSelect(item) : ''
                                        }>
                                        <Image
                                            src={item.picture ? item.picture : item.pictureUrl}
                                        />
                                        <p className="brand">{item.brand_name || item.itemBrand}</p>
                                        {item.price > 0 && (
                                            <p className="price bold">
                                                {Formatter.price(item.price)}
                                            </p>
                                        )}

                                        {isExternalItem(item) && (
                                            <div className="shop-externally">
                                                Shop at {item.retailer_name}
                                            </div>
                                        )}
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </Col>
            )}
        </div>
    );
};

import React, { useEffect } from 'react';
import { StylingButton } from 'components';
import { trackEvent, MP_EVENTS, MP_VALUES } from 'services/mixpanel';

interface ILetGetStyling {
    title: string;
    helpText: string;
    hyperText: string;
}

const trackHelpCenterClick = () =>
    trackEvent({ name: MP_EVENTS.HOW_IT_WORKS_HELP_CENTER, properties: {} });

const registerLinksEvents = () => {
    const helpCenterButton = document.querySelector('.helpCenter');
    if (helpCenterButton) {
        helpCenterButton.addEventListener('click', trackHelpCenterClick);
    }
};

const tracking = { source: MP_VALUES.HOW_IT_WORKS_PAGE, element: 'how it works steps' };

const LetGetStyling = (props: { data: ILetGetStyling }) => {
    const data = props.data;
    useEffect(() => {
        registerLinksEvents();
    }, []);
    return (
        <div className={'how-it-works section styling'}>
            <div className="letsGet">
                <div className="styleTitle">{data.title}</div>
                <StylingButton className="how-it-works" tracking={tracking}/>
                <div className="helpText">
                    {data.helpText}{' '}
                    <a target="_blank" href="https://wishi.zendesk.com/" className="helpCenter">
                        {data.hyperText}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LetGetStyling;

import { MP_VALUES } from "services/mixpanel"

export const getOutfitUrl = (uuid: string) => 
    `${window.origin}/feed/outfit/${uuid}`

export const getStylistSearchUrl = (relative = true) => 
    `${ relative ? '' : window.origin}/stylistSearch`

export const getChatUrl = (stylistId: string, userId: string, relative = true) => 
    `${ relative ? '' : window.origin}/chat/${stylistId}/${userId}`

export const getPageType = () => {
    // order of checks is important. first item then outfit and then feed
    // otherwise need to use regex to match the correct path
    const path = window.location.pathname
    let ret = undefined;
    if (path === '/') {
        ret = MP_VALUES.LANDING_PAGE;
    } else if (path.startsWith('/stylistSearch')) {
        ret = MP_VALUES.STYLISTS_PAGE
    } else if (path.includes('/item/')) {
        ret = MP_VALUES.PDP_PAGE
    } else if (path.includes('/outfit/')) {
        ret = MP_VALUES.OUTFIT_PAGE
    } else if (path.includes('/feed')) {
        ret = MP_VALUES.FEED_PAGE
    } else if (path.startsWith('/stylist/')) {
        ret = MP_VALUES.STYLIST_PROFILE_PAGE
    } else if (path === '/pricing') {
        ret = MP_VALUES.PRICING_PAGE
    } else if (path === '/how-it-works') {
        ret = MP_VALUES.HOW_IT_WORKS_PAGE
    } else if (path === '/gift-cards') {
        ret = MP_VALUES.GIFT_CARDS_PAGE
    }
    return ret;
}
import secureLocalStorage from 'react-secure-storage';
import { Client } from 'types/user';

// const fallbakcStylists: { [index: string]: Record<string, string> } = {
//     female: {
//         Mika: '1e7c0536-5458-11e4-b',
//         Daphne: '1e7c723d-5458-11e4-b',
//         Sophia: 'fb86adfd-4206-11ea-b'
//     },
//     male: {
//         Alan: '46e2c71c-2c2a-11e6-b',
//         Tara: '1e7c723d-5458-11e4-b',
//         Meredith: '44f70dd9-c9f4-11eb-b'
//     },
//     nonbinary: {
//         Mika: '1e7c0536-5458-11e4-b',
//         Alan: '46e2c71c-2c2a-11e6-b',
//         Daphne: '1e7c723d-5458-11e4-b'
//     }
// };

export const redirectUserLogin = (hasActiveSessions: boolean) => {
    if (window.location.pathname == '/' && hasActiveSessions) window.location.href = '/inbox';

    if (
        !hasActiveSessions &&
        (window.location.pathname == '/' || window.location.pathname == '/inbox')
    )
        window.location.href = '/stylistSearch';
};

// export const defaultStylists = (gender: string) => {
//     if (gender) return Object.values(fallbakcStylists[gender]);
//     else return Object.values(fallbakcStylists['female']);
// };

export const extractUserData = (user: Client) => {
    if (user && user.user_uuid && user.email && user.first_name)
        return {
            key: user.user_uuid,
            email: user.email,
            name: `${user?.first_name} ${user.last_name}`
        };
    else return null;
};

export const isUserLoggedIn = (user: any) => {
    return !!(user?.user_uuid || user?.uuid);
};

export interface CurrentUser {
    isAdmin: boolean;
    uuid: string;
    token: string;
}

export const setCurrentUser = (user: CurrentUser) =>
    secureLocalStorage.setItem('secure-user', user);
export const getCurrentUser = (): CurrentUser =>
    secureLocalStorage.getItem('secure-user') as CurrentUser;
export const removeCurrentUser = () => secureLocalStorage.removeItem('secure-user');

export const getUserToken = () => getCurrentUser()?.token;
export const getUserId = () => getCurrentUser()?.uuid;

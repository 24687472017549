import { useMemo } from 'react';

import { GeneralUser } from '../types/user';

export const useUserName = (user: GeneralUser) =>
    useMemo(() => {
        let fname, lname;

        if (user) {
            if (user.name) {
                [fname, lname] = user.name.split(' ');
            } else {
                fname = user.first_name;
                lname = user.last_name;
            }
        }

        return {
            fname,
            lname,
            fullname: [fname, lname].join(' '),
            initials: [fname && fname[0], lname && lname[0]].join(' ')
        };
    }, [user]);

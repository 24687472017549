import { me, rootSplitApi } from 'store/root-api-slice';

import { Message } from './inbox-types';

const apiWithTag = rootSplitApi.enhanceEndpoints({ addTagTypes: ['Inbox'] });

const extendedApi = apiWithTag.injectEndpoints({
    endpoints: (build) => ({
        getMessages: build.query<Message[], void>({
            query: () => ({
                url: `proxy/user/${me}/inbox`,
                method: 'GET'
            }),
            transformResponse: (response: Message[]): Message[] => {
                return response.map((message) => {
                    return {
                        ...message,
                        isSubscription: message.subscription_id !== null
                    };
                });
            },
            providesTags: () => [{ type: 'Inbox', id: 'MessagesList' }]
        })
    })
});

export const { useGetMessagesQuery } = extendedApi;

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { StylingButton } from 'components';
import { MP_VALUES } from 'services/mixpanel/consts';

export interface KarlaProps {
    data: {
        img: {
            src: string;
            fallback: string;
        };
        text: string;
    };
}

const tracking = {
    source: MP_VALUES.LANDING_PAGE,
    element: 'landing page karla'
}

const Karla: React.FC<KarlaProps> = ({ data } ) => (
    <Row className="karla">
        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
            <ImgWithFallback
                src={data.img.src}
                fallbackSrc={data.img.fallback}
                className="image"
                alt="Karla"
            />
        </Col>
        <Col xs={{ span: 12, order: 1 }} md={{ span: 6, order: 2 }}>
            <div className="text">
                <p>{data.text}</p>
                <StylingButton tracking={tracking}/>
            </div>
        </Col>
    </Row>
);

export default Karla;

import { connect } from 'react-redux';
import { setBookingSource, setMixpanelStore } from 'store/common/actions';

import FavoritesComponent from './Favorites';

const mapStateToProps = ({
    user: { favoriteSection },
    common: { mixpanelStore }
}) => ({
    favoriteSection,
    mixpanelStore,
});

const mapDispatchToProps = (dispatch) => ({
    setBookingSource: (source) => setBookingSource(dispatch, source),
    setMixpanelStore: (data) => setMixpanelStore(dispatch, data),
});

export const Favorites = connect(mapStateToProps, mapDispatchToProps)(FavoritesComponent);

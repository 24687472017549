import './style.scss';

import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import content from 'content.json';
import { MP_EVENTS, MP_PROPS, MP_VALUES, trackEvent } from 'services/mixpanel';
import { getStylistSearchUrl } from 'services/utils/url-utils';
import { isUserLoggedIn } from 'services/utils/user-utils';
import { useUserStore } from 'store/user/reducer';

const { landing } = content;

interface IStylingButton {
    tracking: {
        source: MP_VALUES;
        element?: string;
    };
    text?: string;
    className?: string;
}

const StylingButton: React.FC<IStylingButton> = ({ tracking, text, className }) => {
    const user = useUserStore((store) => store?.user);

    const onClick = () => {
        trackEvent({
            name: MP_EVENTS.TAKE_ONBOARDING_QUIZ_CLICK,
            properties: {
                [MP_PROPS.SOURCE]: tracking.source,
                [MP_PROPS.ELEMENT]: tracking.element,
            }
        });
    };

    return (
        <Link
            to={isUserLoggedIn(user) ? getStylistSearchUrl() : '/onboarding'}
            state={{ fromHome: true, mpSource: tracking.source }}
            onClick={onClick}
            className={`styling-button ${className}`}>
            <Button variant="dark" className={className}>
                {text ? text : landing.button.text}
            </Button>
        </Link>
    );
};

export default StylingButton;

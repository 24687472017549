import { FC, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import {
    StripeExpressCheckoutElementClickEvent,
    StripeExpressCheckoutElementShippingAddressChangeEvent
} from '@stripe/stripe-js';
import { MP_EVENTS, MP_PROPS, trackEvent } from 'services/mixpanel';
import { sentryException } from 'services/SentryLogging';
import {
    useLazyGetExpressCheckoutPaymentIntentQuery,
    useLazyGetPriceWithTaxQuery
} from 'store/payments/paymentsApiSlice';

import { getClickResolveDetails, getExpressCheckoutOptions, getLineItems } from './helpers';
import { ExpressCheckoutProps } from './types';

export const ExpressCheckoutButton: FC<ExpressCheckoutProps> = ({
    item,
    itemSource,
    lookId,
    itemSize,
    onReady
}) => {
    const stripe = useStripe()!;
    const elements = useElements()!;
    const options = getExpressCheckoutOptions();
    const [createPaymentIntent] = useLazyGetExpressCheckoutPaymentIntentQuery();
    const [getPriceWithTaxes] = useLazyGetPriceWithTaxQuery();
    const [errorMessage, setErrorMessage] = useState<string | undefined>();

    const onConfirm = async () => {
        const { error: submitError } = await elements.submit();

        setErrorMessage('');

        if (submitError) {
            setErrorMessage(submitError.message);
            sentryException(new Error(submitError.message), 'Elements submit error');
            return;
        }

        const { data: paymentIntent, error: paymentIntentError } = await createPaymentIntent({
            items: [{ uuid: item.uuid, size: itemSize! }]
        });

        if (paymentIntentError || !paymentIntent) {
            if (paymentIntentError) {
                const serverError = (paymentIntentError as FetchBaseQueryError).data as {
                    message: string;
                    error: string;
                };

                sentryException(
                    new Error(serverError.message || 'Server error'),
                    serverError.error
                );
            }

            setErrorMessage('Payment failed! Please try again later.');
            return;
        }

        const { error: confirmationError } = await stripe.confirmPayment({
            elements,
            clientSecret: paymentIntent.secret,
            confirmParams: {
                return_url: `${location.href}/confirmation?&orderId=${paymentIntent.orderId}&checkoutSource=${'express checkout'}`
            }
        });

        if (confirmationError) {
            setErrorMessage(confirmationError.message!);
        }
    };

    const onClick = ({ resolve }: StripeExpressCheckoutElementClickEvent) => {
        trackEvent({
            name: MP_EVENTS.PDP_EXPRESS_CHECKOUT_CLICK,
            properties: {
                [MP_PROPS.ITEM_BRAND]: item.brand_name,
                [MP_PROPS.ITEM_UUID]: item.uuid,
                [MP_PROPS.LOOK_UUID]: lookId,
                [MP_PROPS.ITEM_SOURCE]: itemSource
            }
        });

        resolve(getClickResolveDetails(item));
    };

    const onShippingAddressChange = async (
        event: StripeExpressCheckoutElementShippingAddressChangeEvent
    ) => {
        const { address, resolve, reject } = event;
        const { city, state, country, postal_code: postalCode } = address;
        const { data: priceData } = await getPriceWithTaxes({
            items: [{ uuid: item.item_uuid, size: itemSize! }],
            shippingAddress: { city, state, country, postalCode }
        });

        if (priceData) {
            elements.update({ amount: priceData.total * 100 });
            resolve({
                lineItems: getLineItems(item, priceData.tax)
            });
        } else {
            reject();
        }
    };

    return (
        <>
            <ExpressCheckoutElement
                onClick={onClick}
                onConfirm={onConfirm}
                options={options}
                onReady={onReady}
                onShippingAddressChange={onShippingAddressChange}
            />
            {errorMessage && <div className="error-message">Payment error: {errorMessage}</div>}
        </>
    );
};

import './Confirmation.scss';

import { Page } from 'components';
import content from 'content.json';
import React, { FC, useEffect, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useParams, useSearchParams } from 'react-router-dom';
import { addToCloset } from 'redux/reducers/users/actions';
import { Tracking } from 'services';
import { MP_EVENTS, MP_PROPS, setProfileProps, trackEvent } from 'services/mixpanel';
import { useCompleteOrderMutation, useGetOrderDetailsQuery } from 'store/payments/paymentsApiSlice';
import { useUserStore } from 'store/user/reducer';

const texts = content.item;

type TProps = {
    source: string;
};

export const Confirmation: FC<TProps> = ({ source }) => {
    const { outfit_uuid: lookId } = useParams();
    const [searchParams] = useSearchParams();
    const orderId = searchParams.get('orderId') || '';
    const checkoutSource = searchParams.get('checkoutSource') || '';
    const [isItemInCloset, setIsItemInCloset] = useState(false);
    const user = useUserStore((store) => store?.user);
    const dispatch = useDispatch();
    const [completeCheckout] = useCompleteOrderMutation();
    const { data: sessionDetails } = useGetOrderDetailsQuery(
        {
            orderId
        },
        { skip: !orderId }
    );
    const { shippingAddress, items = [] } = sessionDetails || {};

    const addToClosetClick = () => {
        if (!isItemInCloset && items[0]) {
            dispatch(addToCloset(items[0].uuid));
            setIsItemInCloset(true);
        }
    };

    useEffect(() => {
        if (orderId && sessionDetails && user) {
            const { completed, items, priceBreakdown } = sessionDetails;
            const item = items[0];

            completed === false &&
                completeCheckout({ orderId }).then(() => {
                    trackEvent({
                        name: MP_EVENTS.ITEM_ORDERED,
                        properties: {
                            [MP_PROPS.ITEM_UUID]: item.uuid,
                            [MP_PROPS.ITEM_SOURCE]: source,
                            [MP_PROPS.ITEM_BRAND]: item.name,
                            [MP_PROPS.LOOK_UUID]: lookId,
                            [MP_PROPS.CHECKOUT_SOURCE]: checkoutSource || 'regular checkout'
                        }
                    });
                    setProfileProps({
                        [MP_PROPS.LAST_ORDER_DATE]: new Date().toDateString(),
                        [MP_PROPS.LAST_ORDER_AMOUNT]: priceBreakdown.total
                    });
                    Tracking.google({
                        type: 'event',
                        event: 'order_completed',
                        data: {
                            order_amount: priceBreakdown.total,
                            client_full_name: `${user.first_name} ${user.last_name}`,
                            client_uuid: user.user_uuid,
                            client_email: user.email,
                            item_brand: item.name,
                            item_uuid: item.uuid
                        }
                    });
                });
        }
    }, [orderId, sessionDetails, user, completeCheckout, lookId, source, checkoutSource]);

    if (sessionDetails?.status === 'open') {
        // This is temporary. Status should not reflect the internal Stripe order state.
        return <Navigate to="../" />;
    }

    return (
        <Page footer={false} className="confirmation-page">
            {sessionDetails && (
                <Container>
                    <Row>
                        <Col>
                            <div className="confirmation-details-container">
                                <h1>{texts.confirmation.title}</h1>
                                <h4>
                                    {texts.confirmation.text.replace(
                                        '%confirmation%',
                                        sessionDetails.confirmationNumber
                                    )}
                                </h4>
                                {shippingAddress && (
                                    <div className="billing-details">
                                        <p className="title">{texts.confirmation.shipping}</p>
                                        <p>{shippingAddress.name}</p>
                                        <p>
                                            {shippingAddress.line1} {shippingAddress.line2}
                                        </p>
                                        <p>{`${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.postalCode}`}</p>
                                    </div>
                                )}
                                <div
                                    className={`closet ${isItemInCloset ? 'disabled' : ''}`}
                                    onClick={addToClosetClick}>
                                    {isItemInCloset
                                        ? texts.confirmation.added
                                        : texts.confirmation.closet}
                                </div>

                                <Button as={Link} variant="warning" className="continue" to="../">
                                    {texts.confirmation.button}
                                </Button>
                            </div>
                        </Col>
                        {items[0] && (
                            <Col className="d-none d-sm-block">
                                <div className="item-image-container">
                                    <Image src={items[0].pictureUrl} />
                                </div>
                            </Col>
                        )}
                    </Row>
                </Container>
            )}
        </Page>
    );
};

import content from '../../content.json';
import { IActionWithPayload } from '../types';

const { pricing } = content;

export type OrderId = string;
export type StylingSessionRequestId = string;
export type StripeSessionStatus = 'open' | 'complete' | 'expired' | 'paid'; // Stripe order status. complete = valid

export type LineItem = {
    uuid: string;
    size: string;
};

export type StylingSessionType = 'one-time' | 'subscription';

export type StylingSessionGoal =
    | 'GOAL_CLOSET_CLEANOUT'
    | 'GOAL_CASUAL'
    | 'GOAL_BLACK_TIE'
    | 'GOAL_PARTY'
    | 'GOAL_WEDDING'
    | 'GOAL_NEW_SEASON'
    | 'GOAL_EVENT'
    | 'GOAL_HOLIDAY'
    | 'GOAL_WORKWEAR'
    | 'GOAL_SPECIFIC_PIECE'
    | 'GOAL_OTHER';

export type StylingSessionPlan = 'mini' | 'major' | 'lux';

export type GetItemCheckoutSessionRequest = {
    items: LineItem[];
    returnUrl: string;
};

export type GetItemCheckoutSessionResponse = { secret: string; id: string };

export type GetOrderDetailsRequest = { orderId: OrderId };

export type GetOrderDetailsResponse = {
    confirmationNumber: string;
    status: StripeSessionStatus;
    shippingAddress: {
        country: string;
        state: string;
        city: string;
        line1: string;
        line2: string;
        postalCode: string;
        name: string;
    };
    items: {
        name: string;
        pictureUrl: string;
        price: string;
        uuid: string;
    }[];
    completed: boolean;
    priceBreakdown: {
        total: string;
        subtotal: string;
        tax: string;
        shipping: string;
        taxRate: string;
    };
};

export type GetExpressCheckoutPaymentIntentRequest = {
    items: LineItem[];
};

export type GetExpressCheckoutPaymentIntentResponse = { secret: string; orderId: OrderId };

export type GetPriceWithTaxRequest = {
    items: LineItem[];
    shippingAddress: {
        city: string;
        state: string;
        postalCode: string;
        country: string;
    };
};

export type GetPriceWithTaxResponse = { total: number; tax: number };

export type CompleteOrderRequest = { orderId: OrderId };

export type GetStylingSessionCheckoutRequest = {
    type: StylingSessionType;
    stylistUuid: StylingSessionRequestId;
    goal?: StylingSessionGoal;
    plan: StylingSessionPlan;
    returnUrl: string;
};

export type GetStylingSessionCheckoutResponse = {
    secret: string;
    id: string;
};

export type GetStylingSessionCheckoutDetailsRequest = {
    styleRequestUuid: StylingSessionRequestId;
};

export type GetStylingSessionCheckoutDetailsResponse = {
    completed: boolean;
    plan: StylingSessionPlan;
    total: string;
    subtotal: string;
    status: StripeSessionStatus;
    stylistUuid: string;
    isTrial: boolean;
    coupon: string;
    type: StylingSessionType;
};

export type CompleteStylingSessionCheckoutRequest = {
    styleRequestUuid: StylingSessionRequestId;
};

export enum ActionTypes {
    SET_MODEL = 'payments/SET_MODEL'
}
export type PaymentModel = keyof (typeof pricing.plans)[number]['price'];
export interface PaymentsState {
    model: PaymentModel;
}

export type Actions = IActionWithPayload<ActionTypes.SET_MODEL, PaymentModel>;

import './Checkout.scss';

import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Loader, Page } from 'components';
import content from 'content.json';
import { useIsMobile } from 'hooks';
import { FC } from 'react';
import { Col } from 'react-bootstrap';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Payments } from 'services';
import { useGetItemsCheckoutSessionQuery } from 'store/payments/paymentsApiSlice';
const { item: texts } = content;

const stripePromise = loadStripe(Payments.getStripeKey() as string);

export const Checkout: FC = () => {
    const { itemId = '' } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const size = searchParams.get('size') || '';
    const context = pathname.substring(0, pathname.lastIndexOf('/'));
    const isMobile = useIsMobile();
    const { data: paymentSession, isFetching } = useGetItemsCheckoutSessionQuery(
        {
            items: [{ uuid: itemId, size }],
            returnUrl: `${context}/confirmation?session_id={CHECKOUT_SESSION_ID}&orderId={ORDER_ID}`
        },
        { skip: !(itemId && size) }
    );

    return (
        <Page footer={false} header={!isMobile} className="checkout-page">
            {isFetching && <Loader />}
            <div className="header">
                <div className="back-btn" onClick={() => navigate(-1)} />
                <div>{texts.checkout}</div>
            </div>
            <div>
                <Col className="disclaimer">
                    <p>
                        <i />
                        {texts.disclaimer}
                    </p>
                </Col>
            </div>
            {paymentSession?.secret && (
                <EmbeddedCheckoutProvider
                    key={`session-${paymentSession.secret}`}
                    stripe={stripePromise}
                    options={{ clientSecret: paymentSession.secret }}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </Page>
    );
};

import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import Config from 'services/Config';

import { selectApiToken, selectUserId } from './auth-service';
import { RootState } from './store';

const baseUrl = Config.get('api-gw');

const getDeviceType = () => {
    return Config.isMobile() ? 'web-mobile' : 'web-desktop';
};

export const me = '[self-user-id]';

const rawBaseQuery = fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const token = selectApiToken(getState() as RootState);

        if (token) {
            headers.set('token', token);
        }
        headers.set('X-platform', getDeviceType());
        return headers;
    }
});

const rewriteUrl = (args: string | FetchArgs, selfUserId?: string) => {
    const urlEnd = typeof args === 'string' ? args : args.url;

    if (urlEnd.includes(me)) {
        if (!selfUserId) {
            throw new Error('No authenticated user id');
        }
        const adjustedUrl = urlEnd.replaceAll(me, selfUserId);
        return typeof args === 'string' ? adjustedUrl : { ...args, url: adjustedUrl };
    }
    return args;
};

const replaceSelfAttr = (args: string | FetchArgs, selfUserId?: string) => {
    const hasArgs = typeof args === 'object' && Object.values(args?.params ?? {}).includes(me);

    if (hasArgs) {
        const current = args.params ?? {};
        const newParams = { ...current };
        Object.keys(current).forEach((key) => {
            if (current[key] === me) {
                newParams[key] = selfUserId;
            }
        });
        return { ...args, params: { ...newParams } };
    }
    return args;
};

const dynamicBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions
) => {
    const selfUserId = selectUserId(api.getState() as RootState);
    try {
        const withUrl = rewriteUrl(args, selfUserId);
        const withAttrs = replaceSelfAttr(withUrl, selfUserId);
        return rawBaseQuery(withAttrs, api, extraOptions);
    } catch (error) {
        return {
            error: {
                status: 400,
                statusText: 'Bad Request',
                data: (error as Error).message
            }
        };
    }
};

/**
 * initialize an empty root api service that we'll
 * inject endpoints into later as needed
 */
export const rootSplitApi = createApi({
    baseQuery: dynamicBaseQuery,
    endpoints: () => ({})
});

import { IActionWithPayload } from '../types';

export enum ActionTypes {
    SET_ZE_ON = 'SET_ZE_ON',
    SET_ZE_OFF = 'SET_ZE_OFF',
    SET_BOOKING_SOURCE = 'SET_BOOKING_SOURCE',
    SET_MIXPANEL_STORE = 'SET_MIXPANEL_STORE'
}

export interface MixpanelStruct {
    [index: string]: { [key: string]: string | string[] | boolean | number };
}

export interface BookingSource {
    source: string;
    look_uuid?: string;
}

export interface CommonState {
    bookingSource: BookingSource | undefined;
    zeStatus: boolean;
    mixpanelStore: MixpanelStruct;
}

export type Actions =
    | IActionWithPayload<ActionTypes.SET_ZE_ON, boolean>
    | IActionWithPayload<ActionTypes.SET_ZE_OFF, boolean>
    | IActionWithPayload<ActionTypes.SET_MIXPANEL_STORE, MixpanelStruct>
    | IActionWithPayload<ActionTypes.SET_BOOKING_SOURCE, BookingSource>;

import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { ActionTypes, UserState } from './types';
import { Actions } from '../actions';

const initialState: UserState = {
    user: null,
    redirectUrl: null,
    cart: null,
    twilioInit: false,
    favoriteSection: null
};

export const userReducer = (state = initialState, action: Actions): UserState => {
    switch (action.type) {
        case ActionTypes.LOGOUT_USER: {
            return {
                ...state,
                user: null,
                redirectUrl: null,
                cart: null,
                twilioInit: false,
                favoriteSection: null
            };
        }
        case ActionTypes.REFRESH_USER_DATA:
        case ActionTypes.UPDATE_USER: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                } as any
            };
        }
        case ActionTypes.UPDATE_FAVORITE_SECTION: {
            return {
                ...state,
                favoriteSection: action.payload
            };
        }
        default:
            return state;
    }
};
export const name = 'user';
export const useUserStore: TypedUseSelectorHook<UserState> = (selector, ...args) =>
    useSelector((store: any) => selector(store[name]), ...args);

import { FC } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetStylingSessionCheckoutDetailsQuery } from 'store/payments/paymentsApiSlice';

import { Loader } from 'components';

import { useStylingSessionCheckoutComplete } from './useStylingSessionCheckoutComplete';

export const StylingSessionConfirmation: FC = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const requestId = searchParams.get('requestId') || '';
    const { complete, track } = useStylingSessionCheckoutComplete(requestId);
    const { data: sessionDetails, isFetching } = useGetStylingSessionCheckoutDetailsQuery(
        { styleRequestUuid: requestId },
        { skip: !requestId }
    );

    if (isFetching) {
        return <Loader />;
    }

    if (sessionDetails) {
        if (sessionDetails.completed) {
            track();
            return <Navigate to="/thank-you" />;
        } else {
            if (sessionDetails.status === 'complete' || sessionDetails.status === 'paid') {
                complete();
                return <Loader />;
            } else {
                navigate(-1);
                return <></>;
            }
        }
    }

    return <Navigate to="/" />;
};

import BookBar from './BookBar';
import Experience from './Experience';
import Expertise from './Expertise';
import Matches from './Matches';
import Recommendation from './Recommendation';
import Reviews from './Reviews';
import StylingExperience from './StylingExperience';
import StylistHeader from './StylistHeader';
import Work from './Work';

export default {
    Recommendation,
    Reviews,
    Experience,
    Expertise,
    StylistHeader,
    BookBar,
    Work,
    StylingExperience,
    Matches
};

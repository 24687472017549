import { FC, useCallback } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import * as Elements from './routes/';

export const AppRoutes: FC = () => {
    const { stripeNewItemCheckout, stripeNewSessionCheckout } = useFlags();

    const checkoutComponents = useCallback(
        (source: string) => {
            const { Checkout, Confirmation, ItemCheckout, ItemConfirmation } = Elements;

            return (
                <>
                    <Route
                        path="checkout"
                        element={stripeNewItemCheckout ? <Checkout /> : <ItemCheckout />}
                    />
                    <Route
                        path="confirmation"
                        element={
                            stripeNewItemCheckout ? (
                                <Confirmation source={source} />
                            ) : (
                                <ItemConfirmation />
                            )
                        }
                    />
                </>
            );
        },
        [stripeNewItemCheckout]
    );

    return (
        <Routes>
            <Route path="/" element={<Elements.Landing />} />
            <Route path="how-it-works" element={<Elements.HowItWorks />} />
            <Route path="onboarding/:step?/:innerStepIndex?" element={<Elements.Onboarding />} />
            <Route path="stylist/:id/profile" element={<Elements.Stylist />} />
            <Route path="terms" element={<Elements.Terms />} />
            <Route path="privacy" element={<Elements.Privacy />} />
            <Route path="pricing" element={<Elements.Pricing />} />
            <Route path="our-story" element={<Elements.OurStory />} />
            <Route path="lux" element={<Elements.Lux />} />

            <Route path="gift-cards">
                <Route index element={<Elements.GiftCards />} />
                <Route path="checkout" element={<Elements.GiftCardsCheckout />} />
                <Route path="confirmation" element={<Elements.GiftCardConfirmation />} />
            </Route>

            <Route element={<Elements.ProtectedRoute />}>
                <Route path="goals" element={<Elements.GoalsSelect />} />
                <Route path="plans" element={<Elements.GoalsPlans />} />
                {/*<Route path="checkout" element={<Elements.GoalsCheckout />} />*/}
                <Route
                    path="checkout"
                    element={
                        stripeNewSessionCheckout ? (
                            <Elements.StylingSessionCheckout />
                        ) : (
                            <Elements.GoalsCheckout />
                        )
                    }
                />
                <Route
                    path="checkout/confirmation"
                    element={<Elements.StylingSessionConfirmation />}
                />
                <Route path="quiz" element={<Elements.Quiz />} />
                <Route path="thank-you" element={<Elements.Welcome />} />
                <Route path="inbox" element={<Elements.Inbox />} />
                <Route path="chat/:stylist_id/:user_id" element={<Elements.Chat />} />
                <Route path="stylistSearch" element={<Elements.StylistsList />} />
                <Route path="favorites">
                    <Route index element={<Elements.Favorites />} />
                    <Route path="outfit/:outfit_uuid" element={<Navigate to="favorites" />} />
                    <Route path="outfit/:outfit_uuid/item/:itemId">
                        <Route index element={<Elements.Item />} />
                        {checkoutComponents('favorites')}
                    </Route>
                </Route>
                <Route path="shopping-list">
                    <Route index element={<Elements.ShoppingList />} />
                    <Route
                        path="shopping-list/outfit/:outfit_uuid"
                        element={<Navigate to="shopping-list" />}
                    />
                    <Route path="item/:itemId">
                        <Route index element={<Elements.Item />} />
                        {checkoutComponents('shopping-list')}
                    </Route>
                </Route>
                <Route path="orders" element={<Elements.Orders />} />
                <Route path="profile/:tab?" element={<Elements.Profile />} />
                <Route path="settings/:tab?" element={<Elements.Settings />} />
            </Route>

            <Route path="feed">
                <Route index element={<Elements.Feed />} />
                <Route path="outfit/:outfit_uuid">
                    <Route index element={<Elements.Outfit />} />
                    <Route path="item/:itemId">
                        <Route index element={<Elements.Item />} />
                        <Route element={<Elements.ProtectedRoute />}>
                            {checkoutComponents('feed')}
                        </Route>
                    </Route>
                </Route>
            </Route>

            <Route path="item/:itemId">
                <Route index element={<Elements.Item />} />
                <Route element={<Elements.ProtectedRoute />}>{checkoutComponents('session')}</Route>
            </Route>

            <Route path="gifts" element={<Elements.Campaign campaign="gifts" />} />
            <Route path="wedding" element={<Elements.Campaign campaign="wedding" />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

import { useCallback, useRef } from 'react';
import { Tracking } from 'services';
import {
    isMatchedStylist,
    MP_EVENTS,
    MP_PROPS,
    MP_VALUES,
    registerProperties,
    trackEvent
} from 'services/mixpanel';
import { bookingSourceToProps } from 'services/utils/mixpanel-utils';
import { useCommonStore } from 'store/common/reducer';
import {
    useCompleteStylingSessionCheckoutMutation,
    useGetStylingSessionCheckoutDetailsQuery
} from 'store/payments/paymentsApiSlice';
import { StylingSessionRequestId } from 'store/payments/types';
import { useGetStylistQuery } from 'store/stylist-service/stylist-api-slice';
import { useUserStore } from 'store/user/reducer';
import { useBestMatchesQuery } from 'store/user-service/user-api-slice';

export const useStylingSessionCheckoutComplete = (requestId: StylingSessionRequestId) => {
    const isCompleted = useRef(false);
    const isTracked = useRef(false);
    const user = useUserStore((store) => store?.user);
    const bookingSource = useCommonStore((store) => store.bookingSource);
    const [completeCheckout] = useCompleteStylingSessionCheckoutMutation();
    const { data: checkoutDetails } = useGetStylingSessionCheckoutDetailsQuery(
        { styleRequestUuid: requestId },
        { skip: !requestId }
    );
    const stylistId = checkoutDetails?.stylistUuid;
    const { data: stylist } = useGetStylistQuery(stylistId!, { skip: !stylistId });
    const { data: matches = [] } = useBestMatchesQuery();
    const isMatched = stylistId && isMatchedStylist(matches, stylistId);

    const track = useCallback(() => {
        if (isCompleted.current && !isTracked.current && stylist && checkoutDetails && user) {
            isTracked.current = true;

            const userBookingsCount = parseInt(user.bookings_count);
            const bookingDate = new Date().toISOString();

            Tracking.tag({
                event: 'eec.checkout',
                ecommerce: {
                    checkout: {
                        actionField: { step: 1 },
                        products: [
                            {
                                id: stylist.uuid,
                                name: stylist.name,
                                category: '/category/stylist/',
                                variant: checkoutDetails.plan,
                                quantity: 1,
                                dimension3: 'Ecommerce'
                            }
                        ]
                    }
                }
            });

            Tracking.google({
                type: 'event',
                event: 'booking_completed',
                data: {
                    booking_amount: checkoutDetails.total,
                    plan_type: checkoutDetails.plan,
                    plan_model: checkoutDetails.type === 'one-time' ? 'single' : 'subscription',
                    client_full_name: `${user.first_name} ${user.last_name}`,
                    client_uuid: user.user_uuid,
                    client_email: user.email,
                    stylist_name: stylist.name,
                    stylist_uuid: stylist.uuid
                }
            });

            registerProperties({
                [MP_PROPS.LAST_BOOKING_DATE]: bookingDate,
                [MP_PROPS.LAST_BOOKING_AMOUNT]: checkoutDetails.total
            });

            if (checkoutDetails.type === 'subscription') {
                registerProperties({
                    [MP_PROPS.ACTIVE_SUBSCRIPTION]: MP_VALUES.YES
                });
            }
            if (!userBookingsCount) {
                registerProperties({ [MP_PROPS.FIRST_BOOKING_DATE]: bookingDate });
            }

            trackEvent({
                name: MP_EVENTS.PAYMENT_COMPLETED,
                properties: {
                    [MP_PROPS.PAYMENT_TYPE]: 'order',
                    [MP_PROPS.PLAN_CYCLE]:
                        checkoutDetails.type === 'one-time' ? 'single' : 'subscription',
                    [MP_PROPS.USED_COUPON]: checkoutDetails.coupon ? MP_VALUES.YES : MP_VALUES.NO,
                    [MP_PROPS.COUPON_NAME]: checkoutDetails.coupon,
                    [MP_PROPS.PAYMENT_METHOD]: 'stripe',
                    [MP_PROPS.PAYMENT_AMOUNT]: checkoutDetails.total,
                    ...bookingSourceToProps(bookingSource)
                }
            });
            trackEvent({
                name: MP_EVENTS.SESSION_STARTED,
                properties: {
                    //[MP_PROPS.SESSION_UUID]: result.session_uuid,
                    [MP_PROPS.USER_UUID]: user.user_uuid,
                    [MP_PROPS.USER_EMAIL]: user.email,
                    [MP_PROPS.SESSION_COUNT]: userBookingsCount + 1,
                    [MP_PROPS.SESSION_STATUS]: 'Ongoing',
                    [MP_PROPS.SESSION_AMOUNT]: checkoutDetails.total,
                    [MP_PROPS.IS_TRIAL]: checkoutDetails.isTrial,
                    [MP_PROPS.PLAN_TYPE]: checkoutDetails.plan,
                    [MP_PROPS.STYLIST_NAME]: stylist.name,
                    [MP_PROPS.STYLIST_UUID]: stylist.uuid,
                    [MP_PROPS.IS_SESSION_STYLIST_MATCH]: isMatched
                }
            });
        }
    }, [stylist, checkoutDetails, user, isMatched, bookingSource]);

    const complete = useCallback(async () => {
        if (requestId && checkoutDetails?.completed === false && !isCompleted.current) {
            isCompleted.current = true;
            completeCheckout({ styleRequestUuid: requestId });
        }
    }, [requestId, checkoutDetails, completeCheckout]);

    return { complete, track };
};

import ImgWithFallback from 'components/ImgWithFallback/ImgWithFallback';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { StylingButton } from 'components';
import { trackEvent, MP_EVENTS, MP_VALUES } from 'services/mixpanel';

const tracking = {
    source: MP_VALUES.LANDING_PAGE,
    element: 'landing page how it works'
}

export interface StepsProps {
    data: {
        new: {
            title: string;
            steps: {
                text: string;
                img: {
                    src: string;
                    fallback: string;
                };
            }[];
            link: {
                linkText: string;
                linkPath: string;
            };
        };
    };
}

const Steps: React.FC<StepsProps> = ({ data } ) => {

    const howItWorksTracker = () =>
        trackEvent({
            name: MP_EVENTS.HOW_IT_WORKS_PAGE_VIEW,
            properties: { 'How it works source': 'landing page' }
        });
    const registerLinkEvent = () => {
        const linkButton = document.querySelector('.steps .link');
        if (linkButton) linkButton.addEventListener('click', howItWorksTracker);
    };
    useEffect(() => {
        registerLinkEvent();
    }, []);
    return (
        <Row className="steps">
            <Col xs={12}>
                <Container className="stepsCon">
                    <div className="main-title">
                        <p className="section-title">{data.new.title}</p>
                    </div>

                    <Row className="steps-container">
                        {data.new.steps.map((step, index) => (
                            <Col xs={12} md={4} xl={3} key={step.text} className="step">
                                <Row
                                    className={'flex-nowrap text'.concat(
                                        (index + 1).toString()
                                    )}
                                >
                                    <div className="num">{index + 1}</div>
                                    <p className={'text'.concat((index + 1).toString())}>
                                        {step.text}
                                    </p>
                                </Row>
                                <ImgWithFallback
                                    src={step.img.src}
                                    fallbackSrc={step.img.fallback}
                                    className={`image d-none d-sm-flex ${'image-'.concat(
                                        (index + 1).toString()
                                    )}`}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Col>
            <StylingButton tracking={tracking}/>
            <a href={data.new.link.linkPath} className="steps link">
                {data.new.link.linkText}
            </a>
        </Row>
    );
};

export default Steps;

import { captureException, init } from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const isProduction = process.env.REACT_APP_ENV === 'production';
const sampleRate: number = isProduction ? 0.1 : 0.0;
const apiProvider = (requestUrl: string) => {
    const { hostname } = new URL(requestUrl);
    return hostname.toLowerCase().indexOf('api-gw.api.wishi.me') >= 0 ? 'wishi' : '3rdParty';
};

const initializeSentry = () => {
    init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        beforeSend(event) {
            if (event.request?.url) {
                event.tags = event.tags || {};
                event.tags['apiProvider'] = apiProvider(event.request.url);
            }
            if (!isProduction) {
                console.log(event);
            }

            return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sampleRate,
        environment: process.env.REACT_APP_ENV
    });
};

export const sentryException = (err: Error, customErrorName?: string, message?: string) => {
    if (customErrorName) err.name = customErrorName;
    if (message) err.message = message;
    captureException(err);
};

export default initializeSentry;

import { Client as ClientUser, Stylist as StylistUser } from 'types/user';

import { IAction, IActionWithPayload } from '../types';

export enum ActionTypes {
    LOGOUT_USER = 'user/LOGOUT_USER',
    REFRESH_USER_DATA = 'user/REFRESH_USER_DATA',
    UPDATE_USER = 'user/UPDATE_USER',
    UPDATE_FAVORITE_SECTION = 'user/UPDATE_FAVORITE_SECTION'
}

export interface UserState {
    user: ClientUser | null;
    redirectUrl: string | null;
    cart: any | null;
    twilioInit: boolean;
    favoriteSection: string | null;
}

export type Actions =
    | IAction<ActionTypes.LOGOUT_USER>
    | IActionWithPayload<ActionTypes.REFRESH_USER_DATA, ClientUser | StylistUser | null>
    | IActionWithPayload<ActionTypes.UPDATE_USER, ClientUser | StylistUser | null>
    | IActionWithPayload<ActionTypes.UPDATE_FAVORITE_SECTION, string>;
